import actionHelper from "utils/actionHelper";
import api from "./root.api";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import moment from "moment";
// import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import { ICompanyUser, IGetConfigParams, IRootState } from "./data/root.types";
import { ConfigProductTypes, ConfigSupplierTypes } from "modules/root/data/root.consts";
import { SupplierConfigResource, WebsiteResource, WebsiteSessionCreate } from "tl-api-doc/typescript-generator";
import { ProductType } from "tl-api-doc/typescript-generator/assembly";
import { ISearchSegment } from "../product-search/data/product-search.query/product-search.query.types";
import { Airport } from "../fly-location/types/fly-location.class";
import { ISearchData } from "../fly-search/types/fly-search.types";
import { RootState } from "../../applications/desktop/store";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import TabsName from "../tabs/tabs.name";
import router from "applications/desktop/router";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();
// const socket = new SockJS("events");

const GET_OWN_COMPANY = actionHelper("GET_OWN_COMPANY");
const GET_SITE = actionHelper("GET_SITE");
const GET_OWN_USER = actionHelper("GET_OWN_USER");
const GET_CONFIG = actionHelper("GET_CONFIG");
const SET_ENABLED_PRODUCT_TYPES = actionHelper("SET_ENABLED_PRODUCT_TYPES");
const SET_STUB_LOGIN = actionHelper("SET_STUB_LOGIN");

const SET_ACCESSIBILITY = "SET_ACCESSIBILITY";
const LOAD_ORDER_DATA = "LOAD_ORDER_DATA";
const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
const SET_ORDER_DATA = "SET_ORDER_DATA";
const SET_ACTIVE_ORDER_KEY = "SET_ACTIVE_ORDER_KEY";
const UPDATE_ORDER = "UPDATE_ORDER";
const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA";
const DELETE_ORDER = "DELETE_ORDER";
const SET_MAX_ORDER_TAB = "maxOrderTab";
const SET_IS_ERROR_DIALOG = "SET_IS_ROOT_ERROR_DIALOG";

declare const WEBPACK_IS_TERMINAL: "false" | "true";

const namespaced = true;

const state: IRootState = {
  configs: {
    [ConfigSupplierTypes.PELECARD]: [],
    [ConfigSupplierTypes.COMMON]: [],
    [ConfigSupplierTypes.AMADEUS]: [],
    [ConfigSupplierTypes.KIWI]: [],
    [ConfigSupplierTypes.CREDIT_GUARD]: [],
    [ConfigSupplierTypes.ALP_PACKAGE]: [],
    [ConfigSupplierTypes.OTHER]: [],
  },
  isConfigPending: false,
  isConfigError: false,
  company: null,
  activeOrderKey: null,
  companyId: null,
  isTerminalSearch: false,
  orderData: [],
  maxOrderTab: false,
  uuid: null,
  deviceFingerprint: null,
  websiteKey: null,
  ownUser: null,
  isOwnUserPending: false,
  isPending: false,
  site: null,
  isSitePending: false,
  isAccessibility: false,
  enabledProductTypes: [],
  enabledProductTypesPending: true,
  isEnabledProductTypesError: false,
  isErrorDialog: false
};

const getters: GetterTree<IRootState, RootState> = {
  deviceFingerprint: (state: IRootState) => state.deviceFingerprint,
  displayTab(state: IRootState): {
        flight: boolean;
        package: boolean;
        hotel: boolean;
    } {
    
    let isCarEnable = false;
    const ofranCarsEnabled = state.configs?.OTHER?.find(config => config.name === "OFRAN_CARS_ENABLED");
    if (ofranCarsEnabled && ofranCarsEnabled.value === "true") {
      isCarEnable = true;
    }
    return {
      flight: state.enabledProductTypes?.includes("REGULAR_FLIGHT"),
      package: state.enabledProductTypes?.includes("STATIC_PACKAGE"),
      hotel: state.enabledProductTypes?.includes("HOTEL"),
      car: isCarEnable,
      // insurance: state.enabledProductTypes?.includes("INSURANCE")
      insurance: true // FIXME: remove that when the server returns the such product
    };
  },
  enabledProductTypes(state: IRootState): IRootState["enabledProductTypes"] {
    return state.enabledProductTypes;
  },
  enabledProductTypesPending(
    state: IRootState
  ): IRootState["enabledProductTypesPending"] {
    return state.enabledProductTypesPending;
  },
  isEnabledProductTypesError(
    state: IRootState
  ): IRootState["isEnabledProductTypesError"] {
    return state.isEnabledProductTypesError;
  },
  configs: (state: IRootState): IRootState["configs"] => state.configs,
  isConfigPending: (state: IRootState): IRootState["isConfigPending"] =>
    state.isConfigPending,
  isConfigError: (state: IRootState): IRootState["isConfigError"] =>
    state.isConfigError,
  isAccessibility: (state: IRootState): IRootState["isAccessibility"] =>
    state.isAccessibility,
  company: (state: IRootState): IRootState["company"] => state.company,
  activeOrderKey: (state: IRootState) => state.activeOrderKey,
  maxOrderTab: (state: IRootState) => state.maxOrderTab,
  companyId: (state: IRootState) => state.companyId,
  isTerminalSearch: (state: IRootState) =>
    state.isTerminalSearch || WEBPACK_IS_TERMINAL === "true",
  orderData: (state: IRootState) => state.orderData,
  uuid: (state: IRootState) => state.uuid,
  isB2b: (state: IRootState): boolean =>
    state.isTerminalSearch && !!state.uuid && state.uuid !== "null" && state.site?.type !== "AGENCY_SHOWCASE",
  isB2b2c: (state: IRootState): boolean => state.isTerminalSearch && state.site?.type === "AGENCY_SHOWCASE",
  websiteKey: (state: IRootState) => state.websiteKey,
  isOwnUserPending: (state: IRootState): IRootState["isOwnUserPending"] =>
    state.isOwnUserPending,
  ownUser: (state: IRootState): IRootState["ownUser"] => state.ownUser,
  site: (state: IRootState): IRootState["site"] => state.site,
  isErrorDialog: (state: IRootState): IRootState["isErrorDialog"] => state.isErrorDialog
};

const actions: ActionTree<IRootState, RootState> = {
  async getEnabledProductTypes(
    {
      commit,
      dispatch
    },
    {
      websiteKey,
      productType
    }: {
            websiteKey: string;
            productType?: string;
        }
  ) {
    commit(SET_ENABLED_PRODUCT_TYPES.INITIAL);
    try {
      const enabledProductTypesData = (
        await api.getEnabledProductTypes(websiteKey)
      ).data;
      commit(SET_ENABLED_PRODUCT_TYPES.SUCCEEDED, enabledProductTypesData);
      if (!productType) {
        dispatch("redirectUser");
      }
    } catch (error) {
      commit(SET_ENABLED_PRODUCT_TYPES.FAILED, error);
    }
  },
  async redirectUser({ getters }) {
    if (getters.displayTab.flight) {
      // return;  // TODO это не нужно так как мы всегда находимся н полетах при запуске
      //   if (router.currentRoute.path !== "/flight") {
      //     return router.replace("/flight");
      //   }
      // } else if (getters.displayTab.package) {
      //   if (router.currentRoute.path !== "/package") {
      //     return router.replace("/package");
      //   }
      // } else if (getters.displayTab.hotel) {
      //   if (router.currentRoute.path !== "/hotel") {
      //     return router.replace("/hotel");
      //   }
      // } else {
    }
  },
  async getConfig({ commit }, { uuid }: IGetConfigParams): Promise<void> {
    try {
      commit(GET_CONFIG.INITIAL);

      // pelecard filters
      const filter = {
        productTypes: [ConfigProductTypes.PAYMENTS, ConfigProductTypes.OTHER],
        suppliers: [
          ConfigSupplierTypes.COMMON,
          ConfigSupplierTypes.PELECARD,
          ConfigSupplierTypes.CREDIT_GUARD,
          ConfigSupplierTypes.OTHER
        ]
      };

      const dataConfig = (
        await api.getConfigs({
          filter,
          uuid
        })
      ).data.content;

      filter.suppliers.forEach((supplierConfig) => {
        commit(GET_CONFIG.SUCCEEDED, {
          configType: supplierConfig,
          dataConfig: dataConfig.filter((dc) => dc.supplier === supplierConfig)
        });
      });
    } catch (error) {
      commit(GET_CONFIG.FAILED, error);
    }

    return Promise.resolve();
  },
  async getSite({ commit, dispatch }, { key }: { key: string }) {
    try {
      commit(GET_SITE.INITIAL);
      const site = await api.getSiteByKey(key);

      if (site.data.type === "AGENCY_SHOWCASE") {
        dispatch("getStubLogin", { websiteKey: key });
      }

      if (site.data.faviconPath) {
        const links: NodeListOf<HTMLLinkElement> = document.querySelectorAll("link[rel~='icon']");
        if (!links.length) {
          const link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
          link.href = site.data.faviconPath;
        }
        links.forEach((link) => link.href = site.data.faviconPath);
      }

      commit(GET_SITE.SUCCEEDED, site);
      return Promise.resolve(site);
    } catch (error) {
      commit(GET_SITE.FAILED, error);
    }
  },
  // get stub login to b2b2c
  async getStubLogin({ commit, dispatch }: ActionContext<IRootState, RootState>, { websiteKey }: {
        websiteKey: string
    }): Promise<string> {
    commit(SET_STUB_LOGIN.INITIAL);
    try {
      const fp = await fpPromise;
      const result = await fp.get();
      const payload: WebsiteSessionCreate = {
        websiteKey,
        deviceFingerprint: result.visitorId
      };

      const root = document.documentElement;
      const rs = getComputedStyle(root);
      root.style.setProperty("--b2b-primary", rs.getPropertyValue("--b2b2c-primary"));
      root.style.setProperty("--b2b-secondary", rs.getPropertyValue("--b2b2c-secondary"));

      const uuid = (await api.stubLogin(payload)).data;
      commit(SET_STUB_LOGIN.SUCCEEDED, { uuid, deviceFingerprint: result.visitorId });

      dispatch("getOwnUser", { uuid });
      dispatch("getOwnCompany", { uuid });

      // block select tab if route = terms-of-use
      const isNeedBlockSelectTab = router.currentRoute.path === "/terms-of-use";

      dispatch(`${TabsName}/reloadTabsOnLogin`, { uuid, isNeedBlockSelectTab }, { root: true });

      const closeOwnTabOnRealTabClose = () => {
        dispatch(`${TabsName}/closeActiveTab`, null, { root: true });
      };

      window.addEventListener("beforeunload", closeOwnTabOnRealTabClose);

      return Promise.resolve(uuid);
    } catch (error) {
      commit(SET_STUB_LOGIN.FAILED, error);
      return Promise.reject(error);
    }
  },

  async getOwnCompany({ commit }, { uuid }: { uuid: string }) {
    try {
      commit(GET_OWN_COMPANY.INITIAL);
      const company = await api.getOwnCompany(uuid);
      commit(GET_OWN_COMPANY.SUCCEEDED, company);
      return Promise.resolve(company);
    } catch (error) {
      commit(GET_OWN_COMPANY.FAILED, error);
    }
  },

  async getOwnUser(
    { commit },
    { uuid }: { uuid: string }
  ): Promise<ICompanyUser> {
    try {
      commit(GET_OWN_USER.INITIAL);
      const ownUser = await api.getOwnUser(uuid);
      commit(GET_OWN_USER.SUCCEEDED, ownUser);
      return ownUser.data;
    } catch (error) {
      commit(GET_OWN_USER.FAILED, error);
      return Promise.reject();
    }
  },

  saveOrderData({ state }) {
    const actualOrderData = JSON.stringify({
      activeOrderKey: null, // state.activeOrderKey,
      orderData: state.orderData
    });

    // sessionStorage.setItem('terminalSearchData', actualOrderData)
  },
  updateOrderData({
    commit,
    dispatch
  }, orderData) {
    if (orderData) {
      commit(UPDATE_ORDER_DATA, orderData);
    }
    dispatch("saveOrderData");
  },
  loadOrderData({
    commit,
    dispatch
  }) {
    const loadedOrderData = JSON.parse(
      sessionStorage.getItem("terminalSearchData")
    );
    const key = JSON.parse(window.sessionStorage.getItem("routeParams"));
    dispatch("getSite", { key: key?.key });
    commit(LOAD_ORDER_DATA, loadedOrderData);
  },
  checkSearchType({
    state,
    commit,
    dispatch
  }, query) {
    if (query && Object.keys(query).length) {
      const [companyId, aia] = query.companyId.split("_");
      const {
        locale,
        key,
        uuid,
        access
      } = query;
      const {
        appType,
        searchData
      } = query;
      const routeParams: any = {
        aia: Boolean(aia),
        access,
        companyId,
        key,
        locale,
        uuid
      };
      if (appType) {
        if (appType === "flight-home") {
          // if (router.currentRoute.path !== "/flight") {
          //   router.replace("/flight").then().catch();
          // }
        } else if (appType === "package-home") {
          // if (router.currentRoute.path !== "/package") {
          //   router.replace("/package").then().catch();
          // }
        } else if (appType === "hotel-home") {
          // if (router.currentRoute.path !== "/hotel") {
          //   router.replace("/hotel").then().catch();
          // }
        } else {
          // TODO:
        }
        routeParams.appType = appType;
      }
      if (searchData) {
        routeParams.searchData = searchData.replace(/&quot;/g, "\"");
      }
      window.sessionStorage.setItem("routeParams", JSON.stringify(routeParams));

      try {
        if (uuid) {
          const {
            host,
            hostname,
            href,
            origin,
            pathname,
            port,
            protocol,
            search
          } = window.location;

          // create ws url for stomp
          const wsProtocol = protocol === "https:" ? "wss:" : "ws:";
          const wsUrl = `${wsProtocol}//${hostname}:${port}/events`;

          const stompClient = Stomp.client(wsUrl);
          stompClient.debug = () => {
            console.warn("stompClient debug");
          };
          stompClient.connect({}, () => {
            stompClient.subscribe(`/topic/${uuid}`, (message) => {
              // console.log(message.body)
              stompClient.send("/app/responses", {}, JSON.stringify(message));
            });
          });

          dispatch("getOwnUser", { uuid });
          dispatch("getOwnCompany", { uuid });
        }
      } catch (e) {
        console.error(e);
      }

      dispatch("getSite", { key });
      commit(SET_SEARCH_TYPE, {
        companyId,
        aia,
        key,
        uuid,
        access
      });
      if (access) {
        commit(SET_ACCESSIBILITY, access);
      }
      if (companyId === "1e6cb1fb-adaf-4bf8-92e9-90c1f7eb4b63") {
        const root = document.documentElement;
        root.style.setProperty("--b2b-primary", "#2181c5");
        root.style.setProperty("--b2b-secondary", "#7fad43");
      }
    } else if (WEBPACK_IS_TERMINAL === "false") {
      window.sessionStorage.removeItem("routeParams");
    }
  },
  async initSegments(
    { commit },
    {
      airports,
      searchData
    }: {
            airports: Map<string, Airport[]>;
            searchData: ISearchData;
        }
  ): Promise<ISearchSegment[]> {
    try {
      const isDatePast = moment(searchData.departureDate).isBefore(moment());
      const departureDate = isDatePast
        ? moment()
        : moment(searchData.departureDate);
      const arrivalDate = isDatePast
        ? moment()
        : moment(searchData.arrivalDate);
      return [
        {
          departureDate: departureDate.format("YYYY-MM-DD"),
          fromAirport: airports.get("00")[0],
          toAirport: airports.get("01")[0]
        },
        {
          departureDate: arrivalDate.format("YYYY-MM-DD"),
          fromAirport: airports.get("01")[0],
          toAirport: airports.get("00")[0]
        }
      ];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  setOrderData({ commit }, orderData) {
    sessionStorage.setItem("orderData", JSON.stringify(orderData));
  },
  toggleMaxOrderTab({ commit }, toggle: boolean) {
    commit(SET_MAX_ORDER_TAB, toggle);
  },
  setStoreOrderData({ commit }, orderData) {
    commit(SET_ORDER_DATA, orderData);
  },
  deleteOrder({
    commit,
    dispatch
  }, key) {
    commit(DELETE_ORDER, key);
    dispatch("saveOrderData");
  },
  toggleAccessibility({ commit }, status: boolean) {
    commit(SET_ACCESSIBILITY, status);
  },
  setIsErrorDialog({ commit }, isErrorDialog: boolean) {
    commit(SET_IS_ERROR_DIALOG, isErrorDialog);
  }
};

const mutations: MutationTree<IRootState> = {
  [SET_ENABLED_PRODUCT_TYPES.INITIAL](state: IRootState) {
    state.enabledProductTypesPending = true;
  },
  [SET_ENABLED_PRODUCT_TYPES.SUCCEEDED](
    state: IRootState,
    productTypes: ProductType[]
  ) {
    state.enabledProductTypes = productTypes;
    state.enabledProductTypesPending = false;
  },
  [SET_ENABLED_PRODUCT_TYPES.FAILED](state: IRootState, error: Error) {
    state.isEnabledProductTypesError = true;
    state.enabledProductTypesPending = false;
    console.error(error);
  },
  [GET_CONFIG.INITIAL](state) {
    state.isConfigError = false;
    state.isConfigPending = true;
  },
  [GET_CONFIG.SUCCEEDED](
    state,
    {
      configType,
      dataConfig
    }: { configType: ConfigSupplierTypes; dataConfig: SupplierConfigResource[] }
  ) {
    state.configs[configType] = dataConfig;
    state.isConfigPending = false;
  },
  [GET_CONFIG.FAILED](state, error) {
    state.isConfigPending = false;
    state.isConfigError = true;
    throw new Error(`config getting is error: ${error}`);
  },
  [GET_SITE.INITIAL](state) {
    state.isSitePending = true;
  },
  [SET_ACCESSIBILITY](state, status) {
    state.isAccessibility = status;
    // document.documentElement.style.setProperty('--access', status);
    // document.documentElement.style.setProperty('--b2b-primary', status ? '#004b44' : '#009688');
    // document.documentElement.style.setProperty('--input-placeholder-color', status ? '#313131' : '#414141' );
  },
  [GET_SITE.SUCCEEDED](state, site: { data: WebsiteResource }) {
    state.site = site.data;
    state.isSitePending = false;
  },
  [GET_SITE.FAILED](state, error) {
    // console.log(error)
    state.isSitePending = false;
  },
  [GET_OWN_COMPANY.INITIAL](state) {
    state.isPending = true;
  },
  [GET_OWN_COMPANY.SUCCEEDED](state, company) {
    state.company = { ...company.data };
    state.isPending = false;
  },
  [GET_OWN_COMPANY.FAILED](error) {
    state.isPending = false;
    console.error(error);
  },
  [GET_OWN_USER.INITIAL](state) {
    state.isOwnUserPending = true;
  },
  [GET_OWN_USER.SUCCEEDED](state, user) {
    state.ownUser = user.data;
    state.isOwnUserPending = false;
  },
  [GET_OWN_USER.FAILED](state, error) {
    console.error(error);
    state.isOwnUserPending = false;
  },
  [UPDATE_ORDER_DATA](state, orderData) {
    const {
      type,
      data,
      index
    } = orderData;
    const activeKey = state.activeOrderKey;

    if (index >= 0) {
      if (state.orderData[activeKey][type]) {
        (state.orderData[activeKey][type] as any[])[index] = { ...data };
      } else {
        state.orderData[activeKey][type] = [];
        state.orderData[activeKey][type][index] = { ...data };
      }
    } else {
      state.orderData[activeKey][type] = { ...data };
    }
  },
  [LOAD_ORDER_DATA](state, loadedOrderData) {
    if (loadedOrderData) {
      const {
        activeOrderKey,
        orderData,
        searchQuery
      } = loadedOrderData;
      state.activeOrderKey = activeOrderKey;
      state.orderData = orderData;
    }
  },
  [SET_ACTIVE_ORDER_KEY](state, key) {
    state.activeOrderKey = key;
  },
  [SET_SEARCH_TYPE](state, {
    companyId,
    aia,
    key,
    uuid
  }) {
    state.isTerminalSearch = !!key;
    state.companyId = companyId;
    state.websiteKey = key;
    state.uuid = uuid;
  },
  [SET_STUB_LOGIN.INITIAL](state) {
    console.log("SET_STUB_LOGIN.INITIAL");
  },
  [SET_STUB_LOGIN.SUCCEEDED](state, { uuid, deviceFingerprint }: { uuid: string, deviceFingerprint: string }) {
    state.uuid = uuid;
    state.deviceFingerprint = deviceFingerprint;
    console.log("SET_STUB_LOGIN.SUCCEEDED");
  },
  [SET_STUB_LOGIN.FAILED](state, error: any) {
    console.log("SET_STUB_LOGIN.FAILED", error);
  },
  [SET_MAX_ORDER_TAB](state, toggle: boolean) {
    state.maxOrderTab = toggle;
  },
  [SET_ORDER_DATA](state, orderData) {
    state.activeOrderKey = state.orderData.length;
    state.orderData.push(orderData);
  },
  [UPDATE_ORDER](state, {
    key,
    orderData
  }) {
    state.orderData[key] = { ...orderData };
  },
  [DELETE_ORDER](state, key) {
    state.orderData.splice(key, 1);
  },
  [SET_IS_ERROR_DIALOG](state, isErrorDialog: boolean) {
    state.isErrorDialog = isErrorDialog;
  }
};

export const rootStore: Module<IRootState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};
